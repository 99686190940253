<template>
  <div class="about">
    <div class="banner">
      <img src="https://s3.bmp.ovh/imgs/2022/05/24/a506c1e521ded364.png"
           alt="">
    </div>
    <div class="container">
      <div class="about-us wow animate__fadeInLeft">
        <h2>About us</h2>
        <h3>关于我们</h3>
      </div>
      <div class="br wow animate__fadeInLeft"></div>
      <h2 class="wow animate__fadeInDown">成都乐得科技有限公司</h2>
      <p class="wow animate__fadeInUp"> 一家集信息技术、软硬件开发、智慧油站服务为体的高新技术企业旨在为加油站、撬装站、加气站提供一站式"物联网+”系统解决方案,致力于成为行业物联网方案提供商。随着民营加油站竞争日益激烈,乐油加强油站深度需求开发,推进自身产品及服务的创新度与差异化,为油站提供更契合自身发展零售管理、快捷支付、会员营销、撬点管理等标准产品服务,依照油站的需求不同,量身制定最符合油站的功能,将油站管理者的想法与理念变为可实现化,全面赋能油站,为油站管理运营水平全面升级提供强有力支撑。</p>
      <div class="about-img wow animate__fadeInBottomLeft">
        <div class="about-bg"></div>
        <div class="about-bg1"></div>
        <div class="img ">
          <img src="../assets/about-analysis.png"
               alt="">
        </div>
      </div>
      <div class="about-us wow animate__fadeInDown">
        <h2>Company</h2>
        <h3>公司资质</h3>
      </div>
      <div class="br as wow animate__fadeInDown"></div>
      <div class="certificateList wow animate__fadeInRight">
        <div @click="func('A');"
             :class="['item','cursor',{'as':(state.active=='A') }]">
          公司资质（2）
        </div>
        <div class="br-right"></div>
        <div @click="func('B');"
             :class="['item','cursor',{'as':(state.active=='B') }]">
          软件著作（10）
        </div>
        <div class="br-right"></div>
        <div @click="func('C');"
             :class="['item','cursor',{'as':(state.active=='C') }]">
          软件证书（6）
        </div>
      </div>
      <div class="block block1"
           v-if="state.block=='A'">
        <div class="outSwiperBox wow animate__fadeInUpBig swiperBox">
          <swiper class="swiper-container"
                  :slides-per-view="3"
                  :space-between="50"
                  navigation
                  :pagination="{ clickable: true }"
                  :autoplay="true"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange">
            <!--
        首先可以在  swiper 上设置高度和宽度
        :scrollbar="{ draggable: true }" 就是显示下边的横向的线的 
        暂时就发现个这，别的用法暂没试出来
        navigation 是用来控制向左和向右点击的那两个箭头按钮
        :pagination="{ clickable: true }" 是用来控制最底部的几个点的
        @slidePrevTransitionEnd 这个就是swiper中的官方文档中的事件（event）
        中的一个当滑块向前滚动结束后调用的方法，在vue中的用法就是如此
        -->
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/21d457460ae120e3.jpg"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/a484e2d164c4bc34.png"
                   alt=""></swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="block block1"
           v-if="state.block=='B'">
        <div class="outSwiperBox wow animate__fadeInUpBig">
          <swiper class="swiper-container"
                  :slides-per-view="3"
                  :space-between="50"
                  navigation
                  :pagination="{ clickable: true }"
                  :autoplay="true"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange">
            <!--
        首先可以在  swiper 上设置高度和宽度
        :scrollbar="{ draggable: true }" 就是显示下边的横向的线的 
        暂时就发现个这，别的用法暂没试出来
        navigation 是用来控制向左和向右点击的那两个箭头按钮
        :pagination="{ clickable: true }" 是用来控制最底部的几个点的
        @slidePrevTransitionEnd 这个就是swiper中的官方文档中的事件（event）
        中的一个当滑块向前滚动结束后调用的方法，在vue中的用法就是如此
        -->
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/31/2a21308af4983bb7.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/31/cee8d84aabc805b2.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/31/760e9769f5a10d77.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/31/fd8666d06c84e497.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/31/cf7850311c3b5a55.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/2eacab76c57df482.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/f6393294c41cc960.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/7273de32179dfe84.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/3aa8a68ebc763468.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/5290f89c5b4d4fe2.png"
                   alt=""></swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="block block1"
           v-if="state.block=='C'">
        <div class="outSwiperBox wow animate__fadeInUpBig">
          <swiper class="swiper-container"
                  :slides-per-view="3"
                  :space-between="50"
                  navigation
                  :pagination="{ clickable: true }"
                  :autoplay="true"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange">
            <!--
        首先可以在  swiper 上设置高度和宽度
        :scrollbar="{ draggable: true }" 就是显示下边的横向的线的 
        暂时就发现个这，别的用法暂没试出来
        navigation 是用来控制向左和向右点击的那两个箭头按钮
        :pagination="{ clickable: true }" 是用来控制最底部的几个点的
        @slidePrevTransitionEnd 这个就是swiper中的官方文档中的事件（event）
        中的一个当滑块向前滚动结束后调用的方法，在vue中的用法就是如此
        -->
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/7fd78e3fdbda7f7f.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/d2d71a8252505489.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/6f89510261e3ea4f.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/395eadff6cbe11d5.png"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/c9faaef36191a29f.jpg"
                   alt=""></swiper-slide>
            <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/06/02/df16ffb2a3695bfb.png"
                   alt=""></swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

import WOW from "wow.js";
import SwiperCore, { Navigation, Virtual, Pagination, A11y, Autoplay } from 'swiper';
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import { onMounted, reactive } from '@vue/runtime-core';
SwiperCore.use([Navigation, Virtual, Pagination, A11y, Autoplay]);
export default {
  components: {
    Swiper, SwiperSlide
  },
  setup () {
    let state = reactive({
      active: 'A',
      block: 'A'
    })
    onMounted(() => {
      let wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        callback: function (box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null, // optional scroll container selector, otherwise use window,
        resetAnimation: true, // reset animation on end (default is true)
      });
      wow.init();
    })
    let onSwiper = (swiper) => {
      console.log("111", swiper);
    }
    let onSlideChange = () => {
      console.log('slide change');
    }
    let onSlidePrevTransitionEnd = () => {
      console.log("向前滚动结束了")
    }
    let onSlideNextTransitionEnd = () => {
      console.log("向后滚动结束了")
    }
    let func = (c) => {
      state.active = c;
      state.block = c;
    }

    return {
      onSwiper, onSlideChange, onSlidePrevTransitionEnd, onSlideNextTransitionEnd, state, func
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  height: 100%;
  .banner {
    margin-bottom: 46px;
    height: 480px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    .about-us {
      h2 {
        font-size: 64px;
        font-weight: bold;
        color: rgba(104, 225, 253, 0.23);
        line-height: 75px;
      }
      h3 {
        font-size: 32px;
        font-weight: bold;
        color: #000000;
        line-height: 38px;
        margin-top: -45px;
      }
    }
    .br {
      width: 40px;
      height: 6px;
      background: linear-gradient(180deg, #25d5fd 0%, #5479fb 100%);
      border-radius: 2px 2px 2px 2px;
      margin-top: 11px;
      margin-bottom: 25px;
    }
    h2 {
      font-weight: Bold;
      columns: #000000;
      font-size: 26px;

      line-height: 40px;
    }
    p {
      color: #000000;
      font-size: 18px;
      line-height: 40px;
    }
    .about-img {
      position: relative;
      margin-top: 60px;
      height: 382px;
      margin-bottom: 78px;
      .about-bg {
        position: absolute;
        right: 0;
        top: 26px;
        width: 453px;
        height: 356px;
        background: linear-gradient(180deg, #21aacb 0%, #5f56d9 100%);
      }
      .about-bg1 {
        position: absolute;
        right: 31px;
        top: 0px;
        width: 575px;
        height: 364px;
        background: linear-gradient(180deg, #25d5fd 0%, #5479fb 100%);
      }
      .img {
        z-index: 99;
        position: absolute;
      }
    }
    .as {
      margin-bottom: 0px;
    }
    .certificateList {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 53px;
      .item {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 19px;
      }
      .as {
        color: #3270a5;
      }
      .br-right {
        width: 1px;
        height: 16px;
        border: 1px solid #cecece;
        margin: 0px 16px;
      }
    }
    .outSwiperBox {
      position: relative;
      padding: 0 50px;
      margin-bottom: 20px;
      .swiper-container {
        position: initial;
        width: 100%;
        height: 560px;
        /*height: 300px;*/ /* 这个是用来控制整体的swiper的高度的如果只
        是用导航就写的小点，如果是用作轮播，就写的大点，总之根据你的需要来写*/
        .swiper-wrapper {
          width: 90%;
          .swiper-slide {
            width: 348px;
            // background: #c4c4c4;
            border-radius: 16px 16px 16px 16px;
            text-align: center;
            font-size: 18px;
            // background: pink;
            height: 476px; // 这个就是控制中间滑块的高度的 如果不用做导航，可以设置成100%
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
          }
        }
        /*点击它 进去就能找到如下原码，复制过来修改即可
          import 'swiper/components/navigation/navigation.scss';*/
        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
          left: 0;
          &:after {
            background-color: deeppink;
          }
        }
        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
          right: 0;
          &:after {
            background-color: deeppink;
          }
        }
      }
    }
    .swiperBox {
      .swiper-slide {
        width: 500px !important;
        height: 360px !important;
      }
    }
  }
}
</style>