<template>
  <div class="contact">
    <div class="banner">
      <img src="https://s3.bmp.ovh/imgs/2022/05/24/fc0d9bd7540faa71.png"
           alt="">
    </div>
    <div class="container">
      <div class="title wow animate__fadeInLeft">
        <h2>Contact us</h2>
        <h3>联系我们</h3>
        <div class="br"></div>
      </div>
      <div class="contact-info">
        <div class="top wow animate__fadeInLeft">
          <div class="item">
            <div class="img">
              <img src="../assets/icon-phone.png"
                   alt="">
            </div>
            <p>商务合作电话</p>
            <h3>028-63041025</h3>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/icon-email.png"
                   alt="">
            </div>
            <p>商务合作邮箱</p>
            <h3>leyou001@365leyou.com</h3>
          </div>
        </div>
        <div class="bottom wow animate__fadeInRight">
          <div class="item as">
            <div class="img">
              <img src="../assets/icon-address.png"
                   alt="">
            </div>
            <p>公司地址</p>
            <h3>四川省成都市高新区天府四街时代晶座3栋3楼308号</h3>
          </div>
          <div class="location-img">
            <img src="../assets/map.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import WOW from "wow.js";
export default {
  setup () {
    onMounted(() => {
      onMounted(() => {
        let wow = new WOW({
          boxClass: "wow", // animated element css class (default is wow)
          animateClass: "animated", // animation css class (default is animated)
          offset: 0, // distance to the element when triggering the animation (default is 0)
          mobile: true, // trigger animations on mobile devices (default is true)
          live: true, // act on asynchronously loaded content (default is true)
          callback: function (box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
          },
          scrollContainer: null, // optional scroll container selector, otherwise use window,
          resetAnimation: true, // reset animation on end (default is true)
        });
        wow.init();
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.contact {
  width: 100%;
  height: 100%;
  .banner {
    height: 480px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    .title {
      margin-top: 44px;
      h2 {
        font-size: 64px;
        font-weight: bold;
        color: rgba(104, 225, 253, 0.23);
        line-height: 75px;
      }
      h3 {
        font-size: 32px;
        font-weight: bold;
        color: #000000;
        line-height: 38px;
        margin-top: -45px;
      }
      .br {
        width: 40px;
        height: 6px;
        background: linear-gradient(180deg, #25d5fd 0%, #5479fb 100%);
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        margin-top: 11px;
      }
    }
    .contact-info {
      background: #edf8ff;
      margin: 39px 0px 78px 0px;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 34px 121px 62px 32px;
        border-bottom: 1px solid #bbe0f8;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 0px 32px;
        .location-img {
          max-width: 257px;
        }
      }
      .item {
        .img {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
          }
        }
        p {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          line-height: 28px;
          margin: 13px 0px 30px 0px;
        }
        h3 {
          font-size: 32px;
          font-weight: bold;
          color: #1a6db5;
          line-height: 38px;
        }
      }
      .as {
        padding-top: 26px;
        h3 {
          color: #000000;
        }
      }
    }
  }
}
</style>